nav {
  border-bottom: solid 1px;
  padding-bottom: 1rem;
  padding-top: 0.7rem;
}

nav a {
  text-decoration: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

nav a:first-of-type {
  margin-left: 0;
}

nav a:last-of-type {
  margin-right: 0;
}

nav button {
  display: inline;
  margin-left: 1rem;
  margin-top: 0;
}