#root {
  text-align: center;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select {
  margin-bottom: 1.3rem;
}

a {
  text-decoration: none;
}

input[type='email'],
input[type='text'] {
  width: 12rem;
}

section {
  width: min(29rem, 95vw);
  margin-left: auto;
  margin-right: auto;
}

ul {
  padding-left: 1.7rem;
}

h2 {
  margin-top: 2.1rem;
}

h1 {
  font-size: 2rem;
}

input,
label {
  display: block;
}

input {
  margin-bottom: 0.1rem;
  margin-top: 0.3rem;
  margin-left: auto;
  margin-right: auto;
}

button {
  margin-left: auto;
  margin-right: auto;
}

button,
label {
  margin-top: 1.1rem;
}

form {
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

/* Table style */

table {
  border-collapse: collapse;
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: 1px;
  font-size: 0.8rem;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px 20px;
}

th {
  background-color: rgb(235, 235, 235);
}

td {
  text-align: center;
}

tr:nth-child(even) td {
  background-color: rgb(250, 250, 250);
}

tr:nth-child(odd) td {
  background-color: rgb(245, 245, 245);
}

caption {
  padding: 10px;
}